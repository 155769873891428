import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { PostTitle } from "../components/PostTitle"
import { Header } from "../components/Header"

import { NewsPageQuery } from "../graphql.gen"
import type { AllNewsPostsData } from "../slices/PageDataBodyAllNewsPosts"

export interface NewsPageContext {
	limit: number
	skip: number
	totalPages: number
	currentPage: number
	category?: string
}

const NewsPage = ({
	data,
	pageContext,
}: PageProps<NewsPageQuery, NewsPageContext>) => {
	const page = data.prismicPage
	const title = page?.data.title?.text

	const posts = data.allPrismicNewsPost.nodes
	const categories = data.allCategory.distinct

	const bodySlices = page?.data.body ?? []

	const allNewsPostsData: AllNewsPostsData = {
		__typename: "PrismicPageDataBodyAllNewsPosts",
		posts,
		categories,
		totalPages: pageContext.totalPages,
		currentPage: pageContext.currentPage,
		paginationIndexPath: pageContext.category
			? `/news/${pageContext.category}/`
			: `/news/`,
	}

	const slices = [...bodySlices, allNewsPostsData]

	return (
		<>
			<Header />
			<PostTitle title={title} />
			<MapSlicesToComponents slices={slices} />
		</>
	)
}

export const Head = ({ data }: HeadProps<NewsPageQuery, NewsPageContext>) => {
	const settings = useSiteSettings()
	const page = data.prismicPage

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Blog"
			meta={{
				description: page?.data.meta_description?.text,
				title: page?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

export default withPrismicPreview(NewsPage)

export const query = graphql`
	query NewsPage($limit: Int!, $skip: Int!, $category: String) {
		allPrismicNewsPost(
			limit: $limit
			skip: $skip
			sort: { fields: data___publish_date, order: DESC }
			filter: { tags: { eq: $category } }
		) {
			nodes {
				_previewable
				uid
				url
				tags
				data {
					title {
						text
					}
					summary {
						richText
					}
					image {
						alt
						gatsbyImageData(
							width: 400
							breakpoints: [320, 400]
							layout: FULL_WIDTH
						)
					}
					publish_date
				}
			}

			distinct(field: tags)
		}

		prismicPage(uid: { eq: "news" }) {
			...PageData
		}

		allCategory: allPrismicNewsPost {
			distinct(field: tags)
		}
	}
`
